.error_wrap {
   -webkit-box-align: center;
   align-items: center;
   display: flex;
   -webkit-box-flex: 1;
   flex-grow: 1;
   padding-top: 80px;
   flex-direction: column;

   .error_image_box {
      height: 300px;
      width: 300px;
      display: flex;
      align-items: center;
      margin-bottom: 48px;

      img {
         height: 100%;
         object-fit: contain;
         width: 100%;

      }
   }

   .error_title {
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 31px;
      line-height: 1.2;
      color: #111927;
      text-align: center;
   }

   .error_sub_title {
      font-family: "Inter", sans-serif;
      margin: 4px 0px 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      color: rgb(108, 115, 127);
   }

   .error_bottom {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      margin-top: 48px;

      .home_btn {
         font-family: "Inter", sans-serif;
         display: inline-flex;
         -webkit-box-align: center;
         align-items: center;
         -webkit-box-pack: center;
         justify-content: center;
         position: relative;
         box-sizing: border-box;
         -webkit-tap-highlight-color: transparent;
         background-color: transparent;
         outline: 0px;
         border: 0px;
         margin: 0px;
         cursor: pointer;
         user-select: none;
         vertical-align: middle;
         appearance: none;
         text-decoration: none;
         font-weight: 600;
         font-size: 14px;
         line-height: 1.75;
         min-width: 64px;
         transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
         color: rgb(99, 102, 241);
         border-radius: 12px;
         text-transform: none;
         padding: 9px 16px;

         &:hover {}
      }
   }
}