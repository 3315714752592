.select_language{ 
    margin-left: auto !important;
    display: block !important;
    width: fit-content;
    margin-left: auto !important;
    margin-top: 20px !important;
    margin-right: 10px !important;
    [role="button"] {
        min-width: 80px;
    }
    img{
         
        width: 20px;
    }
}
.auth_box{
    font-family: 'Inter', sans-serif;
    padding: 70px 0;
     
    .auth_back{
        margin-bottom: 32px;
        p{
            display: flex;
            align-items: center;
            font-size: 14px;
            svg{
                margin-right: 8px;
            }
        }
    }
    .auth_data_box{
        width: 552px;
        background-color: rgb(255, 255, 255);
        color: rgb(17, 25, 39);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 30px;
        background-image: none;
        overflow: hidden;
        border-radius: 20px;
        .auth_title_top{
            display: flex;
            align-items: flex-start;
            padding: 32px 24px 0px;
            flex-direction: column;
            .auth_title{
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 16px;
                color: rgb(17, 25, 39);
            }
            .auth_sub_title{
                font-family: 'Inter', sans-serif;
                color: #6c737f;
                font-size: 14px;
                a{
                    margin: 0px;
                    font-size: 14px;
                     
                    line-height: 1.57;
                    font-family: 'Inter', sans-serif;
                    color: rgb(99, 102, 241);
                    text-decoration: none;
                }
            }
        }
        .auth_data_body{
            padding: 32px 24px;
            .auth_form{
                label{
                    font-family: 'Inter', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: rgb(108, 115, 127);
                }
                input{
                    font-family: 'Inter', sans-serif;
                    animation-duration: 10ms;
                    padding: 25px 12px 8px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                }
                .auth_button{
                    margin-top: 24px;
                    text-decoration: none;
                    background-color: rgb(67, 56, 202);
                    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 10px;
                    font-weight: 600;
                    font-size: 0.9375rem;
                    line-height: 1.75;
                    min-width: 64px;
                    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    color: rgb(255, 255, 255);
                    background-color: rgb(99, 102, 241);
                    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 5px;
                    width: 100%;
                    border-radius: 12px;
                    text-transform: none;
                    padding: 11px 24px;
                    font-family: 'Inter', sans-serif;
                }
                p{
                    color: #e70000;
                }
              
            }
            .auth_bottom_text{
                text-align: center;
                margin-top: 24px;
                a{
                    text-align: center;
                    margin: 0px;
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 1.57;
                    font-family: 'Inter', sans-serif;
                    color: rgb(99, 102, 241);
                    text-decoration: none;
                }
            }
        }
    }
}