.top_bar{
    align-items: end;
}
.table_filter {
    // background-color: var(--testColor);
    padding: 24px 20px;

    .label {
        color: #fff;
    }

    .search_box {
        display: flex;
        align-items: center;

        >div {
            width: 100%;
            min-width: 250px;

            > div {
                width: 100%;
            }
        }
    }

    .clear_filter {
        margin-left: 10px;

        button {
            font-weight: bold;
            color: rgb(255, 86, 48);
        }
    }
}

.table_client_wrp {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 8px 0;

    .client_box {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        border-radius: 100px;
        background: rgb(0, 171, 85);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;

    }

    .details {
        h6 {
            font-weight: 600;
            font-size: 0.875rem;
            color: var(--paletteText);
        }

        p {
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 400;
            color: var(--paletteTextGray);
        }
    }
}

.menu_wrp {
    .table_menu {
        background: transparent;
        color: rgb(99, 115, 129);
        box-shadow: none;
        min-width: auto;
        width: 36px;
        height: 36px;
        border-radius: 100px;

        &:hover {
            background: transparent;
            box-shadow: none;
        }

        &:active {
            background: transparent;
            box-shadow: none;
        }
    }
}


@media(max-width: 1199px) {}

@media(max-width: 899px) {}

@media(max-width: 599px) {
    .table_filter {
        // background-color: var(--testColor);
        padding: 24px 20px;
    
        .label {
            color: #fff;
        }
    
        .search_box {
            flex-wrap: wrap;
            gap: 10px;
    
            >div {
                width: 100%;
                min-width: auto;
                margin-left: 0;
    
                >div {
                    width: 100%;
                }
            }
        }
    
        .clear_filter {
            margin-left: 10px;
    
            button {
                font-weight: bold;
                color: rgb(255, 86, 48);
            }
        }
    }
}