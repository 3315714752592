.renderPage{
    background: var(--paletteBackgroundPaper);
    padding: 30px;
    margin: 30px;
}
.render {
    margin-top: 20px;
    .accordionBox{
        margin-bottom: 10px;
        .paypalDetails{
            .plusIcon{
                margin-bottom: 10px;
            }
        }
    }
    .plusIcon {
        border: 1px solid lightgrey;
        border-radius: 8px;
        padding: 0px 10px;
        height: 56px;
        gap: 10px;
        display: flex;
        align-items: center;
        color: var(--paletteText);
        justify-content: space-between;

        .error {
            color: red;
        }

        .green {
            color: green;
        }
    }
}


.webtable {
    width: 100%;
    overflow-x: auto;
    position: relative;
    width: 100%;
    overflow-x: scroll;

    table {
        min-width: 767px;
        tbody{
            tr{
                td{
                    color: var(--paletteText);
                }
            }  
        }
    }
}

.webT {
    border: 1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 20px;

    .tablehead {
        background-color: #efefef;
    }
}

@media (max-width: 991px) {
    .webtable {
        width: 700px;
        overflow-x: scroll;

        table {
            width: 100%;
            min-width: inherit;
        }
    }
}

@media (max-width: 575px) {
    .renderPage {
        padding: 10px !important;
        margin: 0 !important;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}