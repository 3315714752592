:root {
    --paletteText: #111927;
    --paletteBackground: #fff;
    --paletteBackgroundPaper: #fff;
    --paletteDarkBackground: #111927;
    --paletteBackgroundSidebar: #111927;
    --paletteTextActive: #111927;
    --paletteTextGray: #00000099;
    --paletteGrayWhite: #00000099;
    --paletteInputBorder: rgba(145, 158, 171, 0.32);
}

// [data-theme="light"] {
//   --bgColor: #fff
// }

[data-theme="dark"] {
    --paletteText: #fff;
    --paletteBackground: #121212;
    --paletteBackgroundPaper: #212b36;
    --paletteDarkBackground: #121212;
    --paletteBackgroundSidebar: #212b36;
    --paletteTextActive: #fff;
    --paletteTextGray: #747474;
    --paletteGrayWhite: #fff;
    --paletteInputBorder: rgba(145, 158, 171, 0.32);

}