.top_bar {
    align-items: end;
}

.transaction_top_control {
    margin-bottom: 25px;


    .search_box {
        display: flex;
        align-items: center;

        .form_box {

            .MuiStack-root {
                padding: 0;
                overflow: inherit;
            }

            .MuiOutlinedInput-root {
                .MuiOutlinedInput-notchedOutline {
                    border-color: var(--paletteInputBorder) !important;
                    border-radius: 8px;

                    span {
                        color: var(--paletteGrayWhite);
                    }

                }

                &:hover .MuiOutlinedInput-notchedOutline {
                    border-color: var(--paletteText);
                }

            }

        }
    }
}

.transaction_list_wrp {
    margin-top: 30px;
    margin-left: 15px;

    .popover_box {
        width: 100%;
        display: block;

        .popover_input_box {
            min-width: 250px;
        }
    }

    .transaction_card {
        border-radius: 10px;
        background-color: var(--paletteBackgroundPaper);
        box-shadow: 0px 0px 3px 1px #0000001a;
        position: relative;


        .transaction_card_top {
            display: flex;
            flex-wrap: wrap; 
            justify-content: space-between;
            padding: 15px 25px;
            border-bottom: 1px solid var(--paletteInputBorder);
            flex-direction: column;
            h5{
                color: var(--paletteText);
                font-size: 20px;
                font-weight: 700;
                margin-right: 10px;
            }
            .transaction_id {
                p {
                    color: var(--paletteTextGray);
                }
            }

            .transaction_amount {
                justify-content: space-between;
                h3 {
                    color: var(--paletteText);
                    font-size: 20px;
                    font-weight: 700;
                    margin-right: 10px;
                }
            }
        }

        .checkbox_icon {
            position: absolute;
            right: -13px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            border-radius: 100px;
            border: 1px solid #222121;
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .transaction_card_body {
            padding: 15px 25px;
            display: flex; 
            align-items: center;
            justify-content: space-between;
            

            .pdf_btn {
                font-size: 10px;
                padding: 5px;
            }

            .transaction_method {
                margin-bottom: 8px;

                h6 {
                    color: var(--paletteText);
                    display: flex;
                    align-items: center;
                    font-size: 14px !important;

                    svg {
                        margin-right: 5px;
                    }

                }
            }
        }

        h6 {

            color: var(--paletteText);
            font-size: 12px !important;
        }
    }

    .tab_wrp {
        background-color: var(--paletteBackgroundPaper);
        color: rgb(33, 43, 54);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-image: none;
        position: relative;
        box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
        z-index: 0;
        border: 1px solid #eeeeee;
      

        .search_box {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        .tabs_top {
            overflow: hidden;
            min-height: 48px;
            display: flex;
            padding-left: 16px;
            padding-right: 16px;
            background-color: var(--paletteBackgroundPaper);
            > div{
                overflow: auto !important;
                &::-webkit-scrollbar {height: 5px;}
            }

            button[role="tab"]{
                padding: 5px 6px;
            }


            [role="tablist"] {
                 
                +span {
                    background-color: var(--paletteText);
                }

                @media (max-width: 600px) {

                    +span {
                        background-color: transparent;
                    }
                }
            }

            .tab_count {
                height: 24px;
                min-width: 22px;
                line-height: 0;
                border-radius: 6px;
                cursor: default;
                align-items: center;
                white-space: nowrap;
                display: inline-flex;
                justify-content: center;
                text-transform: capitalize;
                padding: 0px 8px;
                font-size: 0.75rem;
                font-family: "Public Sans", sans-serif;
                font-weight: 700;
                margin-right: 8px;
                margin-bottom: 0;
                color: var(--paletteText);
            }


            button {
                font-size: 14px;
                font-weight: 600;
                text-transform: capitalize;
                font-family: "Inter", sans-serif;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                color: var(--paletteTextGray);

                &[aria-selected="true"] {
                    color: var(--paletteTextActive);
                }

                svg {
                    margin-bottom: 0;
                    margin-right: 6px;
                }
            }

        }

        .transaction_card {
            border-radius: 10px;
            background-color: var(--paletteBackgroundPaper);
            box-shadow: 0px 0px 3px 1px #0000001a;
            cursor: pointer;
            border: 1px solid var(--paletteInputBorder);

            .transaction_card_top {
                display: flex;
                flex-wrap: wrap; 
                justify-content: space-between;
                padding: 15px 25px;
                border-bottom: 1px solid var(--paletteInputBorder);
                flex-direction: column;

                .transaction_id {
                    p {
                        color: var(--paletteTextGray);
                        font-size: 14px !important;
                    }
                }

                .transaction_amount {
                    // display: flex;
                    // justify-content: space-between;
                    // height: 24px;

                    h3 {
                        color: var(--paletteText);
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
            }

            .transaction_card_body {
                flex-direction: column;
                padding: 15px 25px;
                .transaction_method{
                    width: 100%;
                }
                .pdf_btn{
                    margin-left: auto;
                }

                .transaction_method {
                    margin-bottom: 8px;

                    h6 {
                        color: var(--paletteText);
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 5px;
                        }

                    }
                }
            }

            h6 {

                color: var(--paletteText);
            }
        }

        .transaction_card_active {
            border: 1px solid #000 !important;
        }

        .transaction_card_inactive {
            border: 5px solid #000 !important;
        }
    }

    .transaction_card_inactive {
        filter: blur(1px);
    }

    .transaction_card_active {
        filter: inherit;
        // border: 1px solid var(--paletteTextGray);
        box-shadow: 0px 0px 6px 1px green;
    }

    .transaction_heading {
        .ListIconBox {
            position: relative;
            width: 100%;
            text-align: right;
            font-size: 20px;

            .listNotification {
                position: absolute;
                height: 20px;
                width: 20px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #1976d2;
                top: -10px;
                right: -9px;
                font-size: 12px;
                color: #fff;
            }
        }
    }
}

.transaction_list_main {
    .transaction_list_box {
        // max-width: 400px;    
        // width: 100%;
        margin-left: 16px;
        margin-top: 16px !important;
        flex: 0 0 31%;
        width: 100%;
        background-color: var(--paletteBackgroundPaper);
    }
}

// .transaction_transaction_list_main__G8C8D .transaction_transaction_list_box {
//     /* max-width: 34%; */
//     flex: 0 0 31%;
//     width: 100%;
// }
.transaction_arrow_icon {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2px;
    gap: 10px;

    button {
        font-size: 12px;
        margin-right: 15px;
        width: 73px;
    }
}

.transaction_invoice_box {
    padding-left: 0px !important;
  
}

.transaction_list_box {
    border: 1px solid #eeeeee;
    min-height: 250px;
    overflow: inherit;
    margin-top: 16px !important;
    padding-right: 15px;
    background-color: var(--paletteBackground);
    position: relative;

    .transaction_box {
        height: 100%;

        .open_amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-right: 5px;

            .OpenAmount {
                font-weight: 600;
                color: var(--paletteText);
            }

            .OpenAmountValue {
                font-weight: 600;
            }

            input {
                padding: 10px;
                max-width: 80px;
            }

        }

        .borderleft {
            border-left: 1px dashed gray;

            .confirmbutton {
                text-align: right;

                button {
                    margin-left: auto;
                    display: block;
                }
            }

            .ads {

                .confirmbutton {
                    text-align: right;

                    button {
                        margin-left: auto;
                        display: block;
                    }

                    .confirm {
                        border: 1px solid #000;
                        color: var(--paletteText);
                    }
                }
            }

        }

        .selected_transaction {
            border: 1px solid rgba(145, 158, 171, 0.3215686275);
            border-radius: 5px;
            padding: 10px;
            margin-right: 15px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 5px;
            font-size: 13px;

            .selected_icon_box {
                display: flex;
                align-items: center;

            }

        }



        .selected_transaction_right {
            border: 1px solid rgba(145, 158, 171, 0.3215686275);
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 5px;
            font-size: 13px;

            .selected_transaction_card {
                p {
                    color: var(--paletteTextGray);
                    b {
                        min-width: 80px;
                        display: inline-block;
                        color: var(--paletteText);
                    }
                }
            }

            .selected_icon_box {
                display: flex;
                align-items: center;

            }

        }


        .transaction_title_left {
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            min-height: 73px;
            font-family: "Inter", sans-serif;
            color: var(--paletteText);
        }

        .transaction_title_right {
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            min-height: 73px;
            font-family: "Inter", sans-serif;
            text-align: right;
            color: var(--paletteText);
        }
    }
    
    .ads{
        .open_amount_card_wrp{
            overflow: auto;
            height: 471px;
        }
    }

}

.card_dropdown {
    text-align: right;

    svg {
        color: var(--paletteText);
    }
}

.open_amount {
    font-size: 12px !important;
    margin-bottom: 10px !important;
    color: white;
}

.disable_submit_button {
    pointer-events: none !important;
    opacity: 0.5 !important;
    font-size: 12px !important;
    // margin-left: 45px !important;
    margin-bottom: 10px !important;
    margin-left: auto;
    display: block;

}

.submit_btn {
    font-size: 12px !important;
    margin-left: auto !important;
    margin-bottom: 10px !important;
    margin-left: auto;
    display: block;
}

.toast_msg {
    top: 50px;
}

.filter_box {
    background-color: #fff;
    box-shadow: 0 0 16px 1px #00000021;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    z-index: 999;

    .popover_input_box {
        margin: 10px 0;
    }
}

.filterIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.disable_button {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

div.rightcolumn {
    @media (min-width: 1200px) {
        max-width: 29%;
        flex-basis: 29%;
    }

    .transaction_list_box {
        margin-left: 0;
        border: 0;
        margin-top: 0 !important;

    }
}

.pad0>div {
    padding: 0;
}

.padleftremove {
    @media (min-width: 1200px) {
        padding-left: 0 !important;
    }
}

div.leftcolumn {
    @media (min-width: 1200px) {
        max-width: 29%;
        flex-basis: 29%;
        margin-top: 16px;
    }

    .leftcolumninner {
        border: 1px solid #eeeeee;
        background-color: var(--paletteBackgroundPaper);

        .transaction_card {
            border: 1px solid #000 !important;
            margin: 0 15px 15px 0;
        }
    }
}

.transaction_heading {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
    width: 100%;
    overflow: hidden;
    min-height: 72px;
    padding-left: 16px;
    padding-right: 16px;
    gap: 6px;
    color: var(--paletteText);

    .transactionsTabWrp {
        display: flex;
         overflow: auto;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex: 0 0 100%;
        gap: 7px;
        &::-webkit-scrollbar { height: 5px;}

        >div {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 23px 8px;
            cursor: pointer;
            border-bottom: 2px solid black;
        }


        .transactionsItems {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 23px 8px;
            color: var(--paletteTextGray);
            border-bottom: 2px solid transparent;
            cursor: pointer;



            &.active {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 23px 8px;
                color: var(--paletteTextGray);
                border-bottom: 2px solid transparent;
                cursor: pointer;

            }
        }

        .transactionsItems.active {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 23px 8px;
            color: var(--paletteTextGray);
            border-bottom: 2px solid transparent;
            cursor: pointer;

        }

    }
}

.confirmbutton {
    text-align: right;
}
.transaction_box{
    width: 100%;
    .transaction_heading{
        font-size: 20px;
        padding: 0 40px;
        padding-top: 20px;
        margin: 0;
        font-weight: 700;
        text-align: center;
        width: 100%;
    min-height: inherit;
    justify-content: center;
    border-bottom: none;
    }
}
// .form_box{
//     div{
//         overflow: hidden !important;
//         width: fit-content !important; 
//     }
// }



@media (max-width:920px) {
    .top_bar {
        .clear_filter {
            margin-left: 10px;
        }
    }

    .padleftremove {
        display: none;
    }

    .transaction_list_main {
        .transaction_list_box {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}

@media (max-width:899px) {
    .transaction_list_main {
        .transaction_add_remove {
            width: 100%;
        }

        .transaction_list_box{
            margin-top: 0;
        }
        
    }
}



@media (min-width:900px) {
   
    .transaction_list_main {
      

        .transaction_list_box{
            margin-top: 0 !important;
        }
        
    
}
} 
@media (min-width:1200px) {
    .transaction_list_main {
        .transaction_list_box{
            margin-top: 16px !important;
        }
        
    
}
}
@media (min-width:600px) {
    .transaction_list_box{
        margin-left: 16px !important;
    }
}


@media (max-width: 480px) {
    .tabs_top {
        [role="tablist"] {
            flex-direction: column;

            button {
                display: flex;
                justify-content: center;

            }

            +span {
                background-color: transparent;
            }
        }
    }
}

@media (max-width: 375px) {
    .transaction_card_body {
        flex-direction: column;
    }

    .transaction_card {
        flex-direction: column;
    }
}