.header_wrp {
    background-color: var(--paletteBackgroundPaper) !important;
    box-shadow: 0px 1px 7px 0px #0000001f;
    >div{
        min-width: 300px;
    }

    .colorModeIcon {
        color: var(--paletteText) !important;
        margin: 0 15px;
    }
    .header_search {
        .search_input_box {
            border-radius: 10px;
            input {
                font-size: 14px;
                font-family: "Inter", sans-serif;
            }
        }
    }

    .header_search_none {
        display: none;
    }

    .select_language {
        margin: 0 15px;

        [role="button"] {
            padding: 10px 35px 10px 20px;
            color: var(--paletteText);

            @media(max-width: 599px) {
                padding: 5px 35px 5px 20px;
            }

            img {
                width: 25px;
            }
        }
    }

    .logoutBtn {
        margin-left: 15px;
        background-color: var(--paletteTextActive);
        color: var(--paletteBackground);
    }
}

.sideBar {

    &>div {
        background-color: var(--paletteBackgroundSidebar);
    }

    .divider {
        background-color: #9da4ae;
    }

    .logo_icon {
        width: 82%;
        text-align: center;
        flex: 0 0 auto;

        img {
            width: 80px;
            height: 100%;
            object-fit: contain;
        }

        svg {
            color: #fff;
        }
    }

    .leftIcon {
        color: #9da4ae;
    }

    .dashbord_P {
        .dashbord_item {
            min-height: 48px;
            padding-left: 20px;

            &:hover {
                background-color: #ffffff0a;
            }

            .list_icon {

                display: inline-flex;
                color: #9da4ae;
                min-width: 0;
                margin-right: 24px;

                svg {
                    color: #9da4ae;
                }
            }
        }

    }



    .menu_text {
        span {
            color: #9da4ae;
            font-size: 14px;
            font-weight: 600;
            font-family: "Inter", sans-serif;
        }
    }

    .list_item {
        color: #9da4ae;
        min-width: 0;
        margin-right: 24px;
    }

    .listItem {
        display: block;
    }

    .handleclick {
        padding-left: 20px;
    }

    .dashbord_item {
        min-height: 48px;
        padding-left: 20px;

        .listicon {
            color: #9da4ae;
            min-width: 0;
            margin-right: 24px;
        }
    }

    .bottom_logout {
        svg {
            color: #9da4ae;
        }
    }
  
}
.main_wrp{
    padding: 24px 10px;
    flex-grow: 1;
}



@media(max-width: 599px) {
    .header_wrp {
        .select_language {
            margin-left: auto;
            [role="button"] {
                padding: 5px 35px 5px 20px;
            }
        }
    }
    .main_wrp{
        padding: 24px 10px;
    }
}