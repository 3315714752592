.page_title {
   h5 {
      margin: 0px 0px 8px;
      font-weight: 700;
      line-height: 1.5;
      font-size: 1.25rem;
      font-family: "Inter", sans-serif;
      color: var(--paletteText);
   }
}

.page_location {
   margin-bottom: 40px;

   span {
      line-height: 1.57143;
      font-size: 0.875rem;
      font-family: "Inter", sans-serif;
      font-weight: 400;
      text-decoration: none;
      color: #9da4ae;
      cursor: pointer;

      &:hover {
         text-decoration: underline;
      }
   }
}

.page_top {
   .btn_blue {
      gap: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      vertical-align: middle;
      font-weight: 600;
      font-size: 0.875rem;
      color: rgb(255, 255, 255);
      background-color: rgb(99, 102, 241);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 5px;
      border-radius: 12px;
      text-transform: none;
      padding: 8px 10px;
      margin-left: auto;

      &:hover {
         background-color: rgb(99, 102, 241);
      }
   }
}

.card_wrp {
   display: flex;
   align-items: center;
   justify-content: space-around;
   gap: 10px 0;
   flex-direction: row;
   padding-top: 16px;
   padding-bottom: 16px;
   width: 100%;
   background-color: var(--paletteBackgroundPaper);
   height: fit-content;
   transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
   border-radius: 10px;
   border-radius: 16px;
   z-index: 0;
   margin-bottom: 40px;

   hr {
      margin: 0px;
      flex-shrink: 0;
      border-width: 0px thin 0px 0px;
      border-color: rgba(145, 158, 171, 0.24);
      height: auto;
      align-self: stretch;
      border-style: dashed;
   }

   .progress_card {
      .card_section{
         position: relative;
         display: inline-block;
         .circular_progress_one{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            .circular_progress_one{
               width: 50px !important;
               height: 50px !important;
               transform: rotate(-90deg) !important;
               color: rgb(255, 171, 0) !important;
            }
         }
         .card_progress_box_two{
            top: 0;
            left: 0; 
            bottom: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
         }

         .circular_progress_one{
            width: 50px;
            height: 50px;
            color: rgba(157, 164, 174, 0.13);
         }
      
      }
      &:first-child{
         .circular_progress_one{
            color: rgb(0, 184, 217);
         }
         .circular_progress_two{
            color: #9da4ae21;
         }
          .card_circul_box{
            svg{
               color: rgb(0, 184, 217);
            }
          }
          .card_details{
            .card_amount{
               color: rgb(0, 184, 217);
            }
          }
      }
      &:nth-child(3) {
          
         .circular_progress_one{
            color: rgb(54, 179, 126);
         }
         .circular_progress_two{
            color: #9da4ae21;
         }
          .card_circul_box{
            svg{
               color: rgb(54, 179, 126);
            }
          }
          .card_details{
            .card_amount{
               color: rgb(54, 179, 126);
            }
          }
      }
      &:nth-child(5) {
          
         .circular_progress_one{
            color: rgb(255, 171, 0);
         }
         .circular_progress_two{
            color: #9da4ae21;
         }
          .card_circul_box{
            svg{
               color: rgb(255, 171, 0);
            }
          }
          .card_details{
            .card_amount{
               color: rgb(255, 171, 0);
            }
          }
      }
      &:nth-child(7) {
          
         .circular_progress_one{
            color: rgb(255, 86, 48);
         }
         .circular_progress_two{
            color: #9da4ae21;
         }
          .card_circul_box{
            svg{
               color: rgb(255, 86, 48);
            }
          }
          .card_details{
            .card_amount{
               color: rgb(255, 86, 48);
            }
          }
      }
      &:nth-child(9) {
          
         .circular_progress_one{
            color: rgb(145, 158, 171);
         }
         .circular_progress_two{
            color: #9da4ae21;
         }
          .card_circul_box{
            svg{
               color: rgb(145, 158, 171);
            }
          }
          .card_details{
            .card_amount{
               color: rgb(145, 158, 171);
            }
          }
      }
      .card_content_box {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;
         width: 100%;
         min-width: 120px;

         .card_image {
            margin-right: 15px;
           
         }

         .progress_bar {
            svg {
               circle {}
            }
         }

         .card_details {
            font-family: "Inter", sans-serif;

            h6 {
               font-size: 16px;
               font-family: "Inter", sans-serif;
               font-weight: 600;
               line-height: normal;
               color: var(--paletteText);
            }

            .card_sub_text {
               color: #9da4ae;
               font-size: 14px;
               font-family: "Inter", sans-serif;
               line-height: normal;
               margin-top: 4px;

               b {
                  color: var(--paletteText);
               }
            }

            .card_amount {
               margin-top: 4px;
               line-height: normal;

               font-family: "Inter", sans-serif;
               font-size: 14px;
               font-weight: 700;
            }
         }
      }
   }
}

.tab_wrp {
   background-color: var(--paletteBackgroundPaper);
   color: rgb(33, 43, 54);
   transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   background-image: none;
   overflow: hidden;
   position: relative;
   box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
   border-radius: 16px 16px 0 0;
   z-index: 0;

   .tabs_top {
      overflow: hidden;
      min-height: 48px;
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
      background-color: var(--paletteBackgroundPaper);
      [role="tablist"]{
         + span{
            background-color: var(--paletteText);
         }
      }
      .tab{
         &:first-child .tab_count{
            background-color: #006c9c66;
         }
         &:nth-child(2) .tab_count{
            background-color: #1b806a66;
         }
         &:nth-child(3) .tab_count{
            background-color: #b76e0066;
         }
         &:nth-child(4) .tab_count{
            background-color: #b71d1866;
         }
         &:nth-child(5) .tab_count{
            background-color: #c3c3dd;
         }
         
         &:nth-child(6) .tab_count {
            background-color: #939393;
         }
      }
      

      .tab_count {
         height: 24px;
         min-width: 22px;
         line-height: 0;
         border-radius: 6px;
         cursor: default;
         align-items: center;
         white-space: nowrap;
         display: inline-flex;
         justify-content: center;
         text-transform: capitalize;
         padding: 0px 8px;
         font-size: 0.75rem;
         font-family: "Public Sans", sans-serif;
         font-weight: 700;
         margin-right: 8px;   
         margin-bottom: 0;
         color: var(--paletteText);
      }


      button {
         font-size: 14px;
         font-weight: 600;
         text-transform: capitalize;
         font-family: "Inter", sans-serif;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;
         color: var(--paletteTextGray);
         &[aria-selected="true"]{
            color: var(--paletteTextActive);
         }
      }
    
   }
   .tab_content_box{
      > div{
         padding: 0;
      }
   }  
}



@media(max-width: 1199px) {}

@media(max-width: 899px) {
   .card_wrp {
      flex-wrap: wrap;
      background-color: transparent;
      box-shadow: none;
      gap: 10px;
      justify-content: unset;


      hr {
         display: none;
      }

      .progress_card {
         padding: 10px;
         background-color: var(--paletteBackgroundPaper);
         border-radius: 16px;
         min-width: 48%;
         box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;

         .card_content_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-width: 120px;

            .card_image {
               margin-right: 15px;

            }

            .progress_bar {
               svg {
                  circle {}
               }
            }

            .card_details {
               font-family: "Inter", sans-serif;

               h6 {
                  font-size: 16px;
                  font-family: "Inter", sans-serif;
                  font-weight: 600;
                  line-height: normal;
                  color: var(--paletteText);
               }

               .card_sub_text {
                  color: #9da4ae;
                  font-size: 14px;
                  font-family: "Inter", sans-serif;
                  line-height: normal;
                  margin-top: 4px;

                  b {
                     color: var(--paletteText);
                  }
               }

               .card_amount {
                  margin-top: 4px;
                  line-height: normal;

                  font-family: "Inter", sans-serif;
                  font-size: 14px;
                  font-weight: 700;
               }
            }
         }
      }
   }

   .tab_wrp {
      .tabs_top {
         [role="tablist"] {
            flex-wrap: wrap;

            +span {
               background-color: var(--paletteText);
            }
            
         }
      }
   }
}

@media(max-width: 599px) {
   .card_wrp{
      .progress_card{
         min-width: 100%;
      }
   }
   .tab_wrp {
      .tabs_top {
         [role="tablist"] {
            +span {
               background-color: transparent;
            }
         }
      }
   }
}